@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/colors";
@use "$styles/tools/mixins/typography";

.sectionHeading {
  font-size: 1.2rem;
}

.sectionListItem {
  padding: 0;
  list-style: none;
}

.sectionLink {
  /* overwrite default link styles */
  text-decoration: none !important;
  color: colors.$neutral800 !important;
  font-weight: 400 !important;
  padding: 0 !important;
  @include typography.paragraphLTypography;

  &:hover {
    &:after {
      /* overwrite default hover line */
      background-color: transparent !important;
    }
  }
}
