@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/tools/mixins/typography';
@use '$styles/variables/colors';
.body-copy {
  ul > li {
    margin-bottom: 10px;
    font-family: 'Rubik';
    > p {
      margin: 0;
    }
  }
  ul > li,
  ul > li p {
    @include typography.paragraphMTypography;
    color: colors.$neutral600;
    @include mediaqueries.mediumUp {
      color: colors.$neutral700;
    }
  }
}
> .Button {
  margin-top: 20px;
}
