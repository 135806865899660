@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/colors";
@use "$styles/variables/spacings";
@use "$styles/variables/radii";
@use '$styles/mediaqueries';
@use "$styles/tools/mixins/typography";


.container {
  max-width: 82%;

  @include mediaqueries.upToSmall {
    max-width: 84%;
  }

  @include mediaqueries.mediumUp {
    padding: 80px 16px;
  }

  @include mediaqueries.extraLargeUp {
    max-width: 1440px;
  }
}

.upper-div {
  text-align: center;

  p {
    text-align: left;

    @include mediaqueries.mediumUp {
      text-align: center;
    }
  }
}

.title {
  text-align: center;

  @include mediaqueries.upToMedium {
    padding-top: 1em;
    @include typography.headlinesH5TypographyStyles;
  }

  > span {
    @include mediaqueries.mediumUp {
      display: block;
    }
  }

}

.GoogleMap {
  padding: 18px;
  margin-top: 80px;

  @include mediaqueries.upToMedium {
    margin-top: 20px;
  }
}
