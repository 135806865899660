@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/variables/spacings';
@use '$styles/variables/elevation';
@use '$styles/variables/breakpoints';
@use '$styles/mediaqueries';
@use '$styles/tools/extends/typography';
@use '$styles/tools/mixins/typography' as typographyMixins;
@use '$styles/variables/fonts';

$speechBubbleMaxWidth: 271px !default;
.col__ctaLookupModal {
  @include mediaqueries.smallUp {
    height: 104px;
  }
  @include mediaqueries.largeUp {
    height: 132px;
  }
}

.colVrmLookup {
  > .ctaLookupModal {
    margin-bottom: spacings.$XXL;
  }
}

.hero {
  &__review {
    @include mediaqueries.upToSmall {
      grid-column: 1 / 3;
    }
  }

  &__renault {
    display: flex;
    justify-content: center;
    position: relative;

    @include mediaqueries.upToSmall {
      grid-column: 3 / 5;

      &:before {
        content: '';
        display: block;
        width: 1px;
        height: 46px;
        position: absolute;
        left: -8px;
        background-color: transparentize($color: colors.$purple300, $amount: 0.1);
      }
    }

    @include mediaqueries.largeUp {
      grid-row: 3;
    }
  }
}

.trustpilot {
  @include mediaqueries.smallUp {
    margin-bottom: 20px;
  }
  @include mediaqueries.mediumUp {
    margin-bottom: 26px;
  }
  @include mediaqueries.largeUp {
    margin-bottom: 28px;
  }
}

.colVrmLookup {
  z-index: 1;
}

.row {
  background: radial-gradient(
    116.13% 363.04% at 0% 0%,
    #5739d8 0%,
    #5739d8 12.02%,
    #5739d6 22.23%,
    #5639d5 30.88%,
    #5538d2 38.2%,
    #5438cf 44.44%,
    #5337cc 49.83%,
    #5237c8 54.62%,
    #5136c4 59.04%,
    #5036c0 63.34%,
    #4e35bb 67.76%,
    #4d34b7 72.54%,
    #4c34b2 77.91%,
    #4a33ae 84.11%,
    #4933aa 91.4%,
    #210f71 100%
  );
  overflow: hidden;
}

.col {
  position: relative;

  @include mediaqueries.upToSmall {
    &__images {
      order: unset;
    }
  }

  @include mediaqueries.smallUp {
    &__images {
      grid-row: 1 / 3;
    }
  }

  @include mediaqueries.largeUp {
    &__images {
      grid-row: 1 / 4;
    }
  }
  &__ctaLookupModal {
    @include mediaqueries.upToSmall {
      margin-bottom: 48px;
      margin-top: 20px;
    }
    @include mediaqueries.smallUp {
      min-width: min-content;
      width: 100%;
      max-width: 307px;
    }
  }
}

.hero {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;
  &__lines {
    @include mediaqueries.upToSmall {
      display: none; // hide the lines for mobile for now
    }
  }
  &__title {
    margin: 0 auto 24px;
    color: colors.$white !important;
    white-space: pre-wrap;
    &::first-letter{
      text-transform: uppercase;
    }

    @include mediaqueries.smallUp {
      @include typographyMixins.headlinesH2TypographyStyles;
      margin-bottom: 32px;
    }

    @include mediaqueries.upToLarge {
      margin-top: 20px;
    }

    @include mediaqueries.largeUp {
      @include typographyMixins.displayMTypographyStyles;
      margin-top: 45px;
      margin-bottom: 40px;
    }
  }

  &__subheading {
    @include typographyMixins.labelMTypographyStyles;
    font-weight: 600;
    color: colors.$purple300;

    @include mediaqueries.smallUp {
      @include typographyMixins.headlinesH6TypographyStyles;
      margin-bottom: 40px;
    }
  }

  &__vrmlookup,
  &__form__vrmlookup {
    position: relative;
    z-index: elevation.$level1 !important;
    margin-bottom: 36px;
    @include mediaqueries.extraSmallOnly {
      margin-top: 47px;
    }
    @include mediaqueries.smallUp {
      margin-bottom: 48px;
    }
  }

  &__lines {
    position: absolute;
    @include mediaqueries.upToSmall {
      display: none; // hide the lines for mobile for now
    }

    @include mediaqueries.upToMedium {
      width: 585px;
      height: 585px;
      right: -50%;
      bottom: -25%;
      top: unset;
    }

    @include mediaqueries.mediumUp {
      width: 855px;
      height: 800px;
      left: -140px;
      right: -40%;
      bottom: -35%;
      top: unset;
    }
  }

  &__fixie {
    display: block;
    position: relative;

    @include mediaqueries.extraSmallOnly {
      transform: translateX(breakpoints.$extraSmallMargins + breakpoints.$extraSmallGutters);
      top: 36px;
    }

    @include mediaqueries.smallOnly {
      top: 60px;
    }

    @include mediaqueries.upToSmall {
      width: 125px;
      height: 250px;
    }

    @include mediaqueries.smallUp {
      transform: translateX(breakpoints.$smallMargins + (breakpoints.$smallGutters / 2));
      width: 150px;
      height: 300px;
    }

    @include mediaqueries.mediumUp {
      position: absolute;
      right: 0;
      bottom: -24px;
      transform: translateX(breakpoints.$mediumMargins + (breakpoints.$mediumGutters / 2));
      width: 200px;
      height: 385px;
    }

    @include mediaqueries.largeUp {
      right: -200px;
      bottom: 0;
      width: 450px;
      height: 473px;
    }

    &_asset {
      display: block;
    }
  }

  &__illustrations {
    display: flex;
    position: relative;
    height: 100%;
    align-items: center;
    width: 100%;

    @include mediaqueries.upToMedium {
      justify-content: flex-end;
    }

    @include mediaqueries.upToSmall {
      &__speechbubbles {
        max-width: 240px;
        margin-top: 30px;
        transform: translate(30px, -60px);
      }
    }

    @include mediaqueries.smallOnly {
      &__speechbubbles {
        transform: translateX(-175px);
      }
    }

    @include mediaqueries.mediumOnly {
      &__speechbubbles {
        position: absolute;
        transform: translateX(-40px);
      }
    }

    &__speechbubble {
      @extend %headlinesH6TypographyStyles;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      &__one {
        z-index: elevation.$level3;
      }
      &__two {
        z-index: elevation.$level2;
      }
      &__three {
        z-index: elevation.$level1;
      }
    }
  }

  @include mediaqueries.upToMedium {
    display: inline-flex;
    align-self: flex-end;
    &__illustrations {
      &__speechbubbles {
        position: relative;
      }
      &__speechbubble {
        &.FixedFirstBubble {
          opacity: 1;
          transform-origin: top right;
          transform: scale(1) rotate(22deg) translate(70px, 102px);
        }

        &.FixedSecondBubble {
          opacity: 1;
          transform-origin: top right;
          transform: scale(0.9) rotate(11deg) translate(25px, 98px);
        }

        &.FixedThirdBubble {
          opacity: 1;
          transform-origin: top right;
          transform: scale(0.8) rotate(0deg) translate(-13px, 73px);
        }
      }
    }
  }

  @include mediaqueries.smallUp {
    display: flex;
    flex-direction: row;
    padding: 0;
    min-height: 720px;

    &__form {
      order: 1;
      flex-direction: column;

      &__reviewdesktop {
        display: block;
        position: absolute;
        width: 100%;
        max-width: 420px;
        bottom: 23px;
        left: 42px;
        order: 3;
        padding: 0;
        margin: 0;
      }
    }

    &__lines {
      display: block;

      @keyframes fadeInLines {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }
    }

    &__illustrations {
      &__speechbubbles {
        margin: 0;
        top: auto;
        z-index: elevation.$level1;
      }

      &__speechbubble {
        position: absolute;
        max-width: $speechBubbleMaxWidth;
        padding: 0 24px !important;
        font-size: 24px;
        align-items: center;
        text-align: center;
        
        &.FixedFirstBubble {
          transform: scale(1) rotate(22deg) translate(0px, -102px);
          transform-origin: top right;
        }

        &.FixedSecondBubble {
          transform: scale(0.9) rotate(11deg) translate(-4px, -48px);
          transform-origin: top right;
        }

        &.FixedThirdBubble {
          transform: scale(0.8) rotate(0deg) translate(-18px, 8px);
          transform-origin: top right;
        }
      }

      &__mascotdesktop {
        position: absolute;
        left: auto !important;
        right: -13px !important;
        bottom: -117px !important;
        width: 739px !important;
        display: block;
        filter: drop-shadow(0px 0px 150px #000);
        backdrop-filter: blur(0);
        transform: translate(350px, 95px);
        height: auto !important;
      }

      &__mascotmobile {
        display: none;
      }
    }
  }

  @include mediaqueries.largeUp {
    &__title {
      display: block;
      margin-bottom: 40px;

      &::first-letter,
      p::first-letter {
        text-transform: uppercase;
      }
      p {
        @include typographyMixins.displayMTypographyStyles;
      }
    }

    &__illustrations {
      &__speechbubbles {
        position: absolute;
        right: ($speechBubbleMaxWidth - breakpoints.$largeMargins);
        min-width: $speechBubbleMaxWidth;

        translate: 0 -36px;
      }

      &__speechbubble {
        @keyframes animateIn {
          from {
            transform: scale(0.625) rotate(-20deg) translate(0px, 150px);
          }

          to {
            transform: scale(0.8) rotate(0deg) translate(-30px, 32px);
          }
        }

        &.FixedSecondBubble {
          transform: scale(0.9) rotate(11deg) translate(-9px, -33px);
        }

        &.FixedThirdBubble {
          transform: scale(0.8) rotate(0deg) translate(-30px, 32px);
        }

        &.FixedPromoBubble {
          transform: scale(0.74) rotate(-8deg) translate(-58px, 114px);
        }
      }
    }
  }
}

:export {
  smallBreakpoint: breakpoints.$smallBreakpoint;
  mediumBreakpoint: breakpoints.$mediumBreakpoint;
  largeBreakpoint: breakpoints.$largeBreakpoint;
}
