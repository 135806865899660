@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/variables/colors';

.PartOfRenault {
  color: colors.$white;
  :global(.Image) {
    width: 87px;
    @include mediaqueries.mediumUp {
      width: 122px;
    }
  }
}
.container {
  display: flex;
}
.label {
  margin-bottom: 8px;
  font-size: 14px;

  @include mediaqueries.mediumUp {
    font-size: 18px;
  }
}
.vertical {
  flex-direction: column;
  font-weight: 600;
}
.horizontal {
  flex-direction: row;
  font-weight: 400;
  .label {
    margin: auto 16px;
    font-size: 14px;
    width: max-content;
    @include mediaqueries.mediumUp {
      margin: auto 26px;
      font-size: 16px;
    }
  }
}
