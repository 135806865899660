@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/colors";
@use "$styles/variables/spacings";
@use "$styles/variables/radii";
@use '$styles/mediaqueries';
@use "$styles/tools/mixins/typography";

.heading {
  @include typography.headlinesH1TypographyStyles;
  text-align: center;
  margin-bottom: spacings.$XL;
  color: colors.$purple800;
}

.sections {
  color: colors.$purple800;
  h3 {
    @include typography.headlinesH6TypographyStyles;
  }
  h4 {
    @include typography.paragraphSTypography;
  }
  
  p {
    margin-bottom: spacings.$XL;
  }

  :global(.tick) {
    // @TODO remove important when all components are in CSS Modules
    margin-right: 0.25em !important;
  }
}

.chat {
  background-color: colors.$white;
  border-radius: radii.$L;
  padding: spacings.$M;
}