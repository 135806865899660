@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/spacings";
@use "$styles/variables/colors";
@use '$styles/mediaqueries';

.component {
    padding-top: 40px;
    padding-bottom: 40px;
}

.linksCard {
    // flex: 1 1 calc(100% / var(--split, 1));
    padding: spacings.$M spacings.$M spacings.$XL;
    background-color: colors.$neutral100;
    
    &.firstLinksCard {
        flex: 1 1 calc(100% / 2);
    }
    
    &.secondLinksCard {
        flex: 1 1 calc(100% / 2);
    }
    
    &.thirdLinksCard {
        flex: 1 1 100%;
    }
}

.heading {
    color: colors.$neutral800;
    // @TODO resolve CMS creating P tags here then remove these styles.
    p {
      font: inherit;
      color: inherit;
      margin: 0;
    }
  }
  
  .viewAllLink {
    padding: 0 !important;
    margin-top: spacings.$M;
  }
  
  .PopularLinksListWrapper {
    @include mediaqueries.extraSmallOnly {
      padding: 0;
    }
  }
  
  .PopularLinksListContainer {
    padding: 40px 16px;
  
    @include mediaqueries.extraSmallOnly {
      border-radius: 0;
    }
    @include mediaqueries.smallUp {
      padding: 40px 56px;
    }
  
    background-color: colors.$white;
  }


$mobileGap: spacings.$L;
$tabletGap: spacings.$XL;

.linksRow {
  display: flex;
  gap: $mobileGap;
  flex-wrap: wrap;
  padding: 0;
  &:not(:last-child) {
    margin-bottom: $mobileGap;
  }

  @include mediaqueries.smallUp {
    flex-wrap: nowrap;
    column-gap: $tabletGap;

    &:not(:last-child) {
      margin-bottom: $tabletGap;
    }
  }
}