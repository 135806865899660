@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/breakpoints";
@use "$styles/variables/colors";
@use "$styles/tools/mixins/typography";
@use '$styles/mediaqueries';

.component {
  display: flex;
  flex-direction: column;
  width: auto;
  text-align: center;

  @include mediaqueries.mediumUp {
    text-align: left;
    border-radius: 0 0 40px 40px;
  }

  &.HasIconTitle {
    align-items: center;

    @include mediaqueries.mediumUp {
      align-items: flex-start;
    }
  }
}

.Row {
  @include mediaqueries.upToMedium {    
    row-gap: 25px;
  }
}

.UspCol {
  display: flex;
  flex-direction: column;
  
  @include mediaqueries.extraSmallOnly {
    row-gap: 32px;
  }

  @include mediaqueries.mediumUp {
    flex-direction: row;
    justify-content: space-evenly;
  }

  @include mediaqueries.smallOnly {
    column-gap: breakpoints.$smallGutters;
  }
  @include mediaqueries.largeOnly {
    column-gap: breakpoints.$largeGutters;
  }
  @include mediaqueries.extraLargeUp {
    column-gap: breakpoints.$extraLargeGutters;
  }
}

.UspContent {
  width: 100%;
  @include mediaqueries.mediumUp {
    max-width: calc(100% / var(--items));
  }
}

.UspImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  @include mediaqueries.mediumUp {
    justify-content: flex-start;
  }
}

.UspImageWrapper {
  width: 64px;
  height: 64px;
  flex-shrink: 0;
}

.UspImage {
  flex-shrink: 0;
  margin-right: 8px;

  @include mediaqueries.mediumUp {
    margin-left: -13px; // because image is exported with paddings
  }
}

.IconTitle {
  @include typography.headlinesH3TypographyStyles;
  word-break: break-all;
  font-size: 24px;
  color: colors.$neutral800;

  @include mediaqueries.smallUp {
    @include typography.headlinesH5TypographyStyles;
    color: colors.$purple800;
  }
}

.UspHeading {
  padding-top: 0;
  @include typography.paragraphLTypography;
  color: colors.$neutral700;

  @include mediaqueries.smallUp {
    padding-top: 8px;
    color: colors.$purple800;
  }
}

.UspSubheading {
  p {
    @include typography.paragraphMTypography;
  }
  > *:last-child {
    margin-bottom: 0;
  }
}

.Heading {
  // @NOTE specificity hack needed because of HeadingLevels styled component
  // @TODO remove specificty hack once HeadingLevels has been moved to CSS Modules
  &.Override {
    display: block;
    @include typography.headlinesH3TypographyStyles;
    margin: 0;
    padding-top: 0;
    text-align: center;
    color: colors.$neutral800;
    
    @include mediaqueries.mediumUp {
      @include typography.headlinesH5TypographyStyles;
      text-align: left;
      color: colors.$purple800;
    }
  }
}