@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/variables/radii';
@use '$styles/mediaqueries';
@use '$styles/variables/spacings';
@use '$styles/tools/mixins/typography';
@use '$styles/variables/fonts';

.component {
  display: inline-flex;
  align-items: center;
  gap: spacings.$XXS;
  padding: spacings.$XS spacings.$S;
  border-radius: radii.$XS;
  background-image: linear-gradient(95deg, #DD205F 33.34%, #B31E4E 104.95%);
  box-shadow: 0px 4.904px 11.034px 0px rgba(2, 13, 39, 0.18), 0px 4.904px 24.521px 0px rgba(2, 13, 39, 0.15);

  @include typography.labelMTypographyStyles;
  font-weight: 600;
  color: colors.$white;

  b {
    font-family: fonts.$campaignFontFamily;
    color: colors.$yellow200;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 13px;
    letter-spacing: 0.34px;
  }
}

.text {
  text-align: center;
}

:export {
  colorYellow200: colors.$yellow200;
}
