@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';

.message {
  display: grid;

  button,
  [class~='btn-'] {
    width: 100%;
    justify-content: center;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.message.sender {
  grid-template-columns: 1fr 50px;
}

.message.sender + .replier {
  margin-top: 50px;
}

.message.replier {
  grid-template-columns: 50px 1fr;
}

.message.replier + .sender {
  margin-top: 50px;
}

.SmallLabel {
  font-size: 16px;
  color: colors.$neutral400;
}

.SmallLabel.sender {
  justify-self: start;
  margin-left: 24px;
}

.SmallLabel.replier {
  grid-column: 2;
  justify-self: end;
  margin-right: 24px;
}

.Avatar.sender {
  grid-row: 2;
  grid-column: 2;
  justify-self: end;
  align-self: end;
}

.Avatar.replier {
  grid-row: 2;
  grid-column: 1;
  justify-self: start;
  align-self: end;
}

.MessageBubble {
  margin-bottom: 40px;
}

.MessageBubble.sender {
  justify-self: start;
  grid-row: 2;
  grid-column: 1 / 2;
}

.MessageBubble.replier {
  justify-self: end;
  grid-row: 2;
  grid-column: 2 / 3;
}
