@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/variables/colors';

.messageBubble {
  position: relative;
  padding: 18px 24px;
  border-radius: 24px;
  @include mediaqueries.mediumUp {
    max-width: 100%;
  }
  &.tail::after {
    bottom: -3px;
    content: '';
    height: 19px;
    position: absolute;
  }
  p {
    margin-bottom: 0;
    b {
      font-weight: 600;
    }
  }
  &.sender,
  &.replier {
    font-size: 16px;
    line-height: 24px;
    width: calc(100% - 20px);
  }
  &.tail::after {
    width: 22px;
    height: 40px;
    clip-path: path(
      'm 12.7111,16.2627 c 5.189274,8.724738 5.191666,8.734149 8.4042,14.1461 -4.2097,2.8045 -10.9599,5.4703 -19.11527,3.9875 2.24621,-0.9626 10.91017,-6.7386 10.58927,-18.2906 0.0389,0.0536 8.526,14.3031 0.1218,0.157 z'
    );
  }
  &.sender {
    margin-right: 20px;
    background-color: colors.$neutral100;
    color: colors.$neutral800;

    p,
    h2 {
      color: colors.$neutral800;
    }

    &.tail::after {
      background-color: colors.$neutral100;
      transform: rotateY(180deg);
      right: -12px;
    }
  }

  &.replier {
    background-color: colors.$cyan100;
    color: colors.$cyan600;
    margin-left: 20px;

    &.tail:after {
      background-color: colors.$cyan100;
      left: -12px;
    }
  }
}
