@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/spacings";
@use '$styles/mediaqueries';

.component {
    list-style: none;
    padding: 0;
    margin: 0;

    @include mediaqueries.smallUp {
        columns: var(--columns, 1);
    }

    > li {
        margin-bottom: spacings.$XS;
    }
}