@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/variables/spacings';
@use '$styles/variables/radii';
@use '$styles/variables/shadows';
@use '$styles/tools/extends/typography';
@use '$styles/mediaqueries';
@use '$styles/variables/breakpoints';
@use '$styles/variables/fonts';

.component {
  background: linear-gradient(90deg, #5739d8 0%, #3a1da7 100%);

  // @NOTE correct position of the postcode look up error message
  :global(.errorMessage) {
    top: calc(100% + spacings.$XXXS);
    left: spacings.$S;

    @include mediaqueries.largeUp {
      top: calc(100% + spacings.$S);
    }
  }

  // adjustment of BrandedIconsListCard when it follows this directly
  + :global(.BrandedIconListCard),
  + :global(.HeroSectionStickyCta) + :global(.BrandedIconListCard),
  + :global(.HUD) + :global(.BrandedIconListCard) {
    padding-top: 0;
    overflow: unset;
  }

  + :global(.BrandedIconListCard) > div,
  + :global(.HeroSectionStickyCta) + :global(.BrandedIconListCard) > div {
    margin-top: -222px;
    @include mediaqueries.largeUp {
      margin-top: 0px;
    }
  }

  &:has(+ :global(.BrandedIconListCard)),
  &:has(+ :global(.HeroSectionStickyCta) + :global(.BrandedIconListCard)) {
    padding-bottom: 225px;
  }

  @include mediaqueries.upToLarge {
    // adjustment of BrandedIconsListCard when it follows this directly
    + :global(.BrandedIconListCard),
    + :global(.HeroSectionStickyCta) + :global(.BrandedIconListCard),
    + :global(.HUD) + :global(.BrandedIconListCard) {
      padding-bottom: 0;
      > :first-child {
        > div {
          margin-top: -90px !important;
        }
      }
    }
  }

  @include mediaqueries.largeUp {
    // adjustment of BrandedIconsListCard when it follows this directly
    + :global(.BrandedIconListCard),
    + :global(.HeroSectionStickyCta) + :global(.BrandedIconListCard),
    + :global(.HUD) + :global(.BrandedIconListCard) {
      padding-bottom: 0;
      > :first-child {
        padding-bottom: 32px;
        > div {
          margin-top: -32px;
        }
      }
    }

    &:has(+ :global(.BrandedIconListCard)),
    &:has(+ :global(.HeroSectionStickyCta) + :global(.BrandedIconListCard)) {
      padding-bottom: 125px;
    }
  }

  &.newBrand {
    overflow: hidden;
    .headingText {
      font-family: fonts.$campaignFontFamily;
      font-size: 36px;
      font-weight: 700;
      letter-spacing: 0.36px;
      line-height: 44px;
      @include mediaqueries.smallUp {
        font-size: 46px;
        letter-spacing: 0.46px;
        line-height: 48px;
      }
      @include mediaqueries.mediumUp {
        min-width: 686px;
      }
    }
    &:has(+ :global(.BrandedIconListCard)),
    &:has(+ :global(.HeroSectionStickyCta) + :global(.BrandedIconListCard)),
    &:has(+ :global(.HUD) + :global(.BrandedIconListCard)) {
      padding-bottom: 70px;
      @include mediaqueries.mediumUp {
        padding-bottom: 100px;
      }
    }
    .lowerContainer {
      margin-top: spacings.$L;
    }
    @include mediaqueries.mediumUp {
      :global(.errorMessage) {
        top: calc(100% + spacings.$S);
      }
    }
  }

  &.curvedShape {
    overflow: visible;
    background: none;
    position: relative;

    .column {
      width: 100%;
      max-width: 1280px;
      margin: auto;
    }

    .lowerContainer {
      margin-bottom: spacings.$M;

      @include mediaqueries.mediumUp {
        margin-bottom: spacings.$L * 2;
      }
    }

    &.noVrm {
      .lowerContainer {
        @include mediaqueries.mediumUp {
          margin-bottom: spacings.$XL;
        }
      }
    }

    .carImage {
      display: none;
      @include mediaqueries.mediumUp {
        display: block;
        scale: 0.85;
        rotate: 3.945deg;
        bottom: -72px;
        left: -267px;
      }
    }
  }
}

.curvedShapeContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    background: linear-gradient(90deg, #5739d8 33%, #3a1da7 66%);
    border-radius: 300vw;
    width: 300vw;
    height: 300vw;
    @include mediaqueries.mediumUp {
      background: linear-gradient(90deg, #5739d8 43%, #3a1da7 57%);
      border-radius: 700vw;
      width: 700vw;
      height: 700vw;
      margin-bottom: spacings.$L;
    }
  }
}

.headingText {
  margin-bottom: spacings.$XXS;
  max-width: 542px;
  margin-left: auto;
  margin-right: auto;

  p {
    font: inherit;
    overflow-wrap: normal;
    word-wrap: normal;
    word-break: normal;
    color: colors.$white;
    margin: 0 auto spacings.$XXS;

    + .priceTag {
      margin-top: 8px;
    }
  }

  &:has(.priceTag) {
    margin-bottom: 20px;
  }

  @include mediaqueries.upToMedium {
    &.smallerFont {
      font-size: 24px !important;
      line-height: 32px !important;
    }
  }

  @include mediaqueries.largeUp {
    margin-bottom: 20px;
  }
}

.carImage {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  margin-left: 60px;
  position: relative;

  @include mediaqueries.mediumUp {
    display: flex;
    position: absolute;
    bottom: -125px;
    left: -270px;
  }
  @include mediaqueries.largeUp {
    bottom: -175px;
    left: -220px;
  }
  @include mediaqueries.extraLargeUp {
    bottom: -175px;
    left: -190px;
  }
}

.carImage.hideBrandMobile {
  @include mediaqueries.upToMedium {
    display: none;
  }
}

.carAsset {
  @include mediaqueries.upToMedium {
    position: relative;
    z-index: 1;
  }
}

.mobileTracks {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 500px;
  height: auto;
  transform: translate(calc(-50% + 82px), calc(-50% + 8px));

  @include mediaqueries.mediumUp {
    display: none;
  }
}

.heading {
  position: relative;
  margin: 0 auto;
  color: colors.$white;
  text-align: center;
  white-space: pre-wrap;
  max-width: max-content;

  &:has(+ .subheading) {
    @include mediaqueries.upToLarge {
      margin-bottom: 0;
      h1 {
        margin-bottom: spacings.$S;
      }
      .priceTag {
        display: none;
      }
    }
  }
  @include mediaqueries.largeUp {
    margin-top: spacings.$XL;
    margin-bottom: spacings.$L;
  }

  &::first-letter,
  p::first-letter {
    text-transform: uppercase;
  }

  u {
    text-decoration: none;
    position: relative;

    &::after {
      content: url('/img/svg/squiggle.svg');
      position: absolute;
      left: 50%;
      translate: -50% 0;
      top: 50%;
    }
  }
}

.tagline {
  font-size: 20px;
  letter-spacing: -0.4px;
  margin-top: spacings.$XXS;
  margin-bottom: spacings.$M;
  max-width: 658px;

  @include mediaqueries.largeUp {
    letter-spacing: 0;
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: spacings.$L;
    min-width: 658px;
  }

  @include mediaqueries.upToMedium {
    &.smallerFont {
      p {
        margin: 0;
      }
    }
  }

  p {
    font-size: inherit;
  }

  u {
    text-wrap: nowrap; // prevent words from wrapping and placing the squiggle misaligned position

    &::after {
      content: url('/img/svg/squiggle-small.svg');
      top: calc(50% + spacings.$XXS);
    }
  }
}

.subheading {
  text-align: center;
  color: colors.$white;

  h2 {
    margin-bottom: calc(spacings.$S + 6px);
    @include mediaqueries.largeUp {
      margin-bottom: spacings.$XL;
    }
  }
  .priceTag {
    margin: 0 0 spacings.$L;
    @include mediaqueries.largeUp {
      display: none;
    }
  }
}

.priceTag {
  margin: 16px auto 0;
  position: relative;
  @include mediaqueries.largeUp {
    left: 100%;
    top: 50%;
    margin-top: 0;
    translate: 8px -50%;
    margin-bottom: spacings.$L;
    position: absolute;
  }
}
.vrm {
  position: relative;
  z-index: 1;

  @include mediaqueries.largeUp {
    form {
      max-width: 1024px;
    }
  }
}

.vrmLookupForm {
  @include mediaqueries.mediumUp {
    flex-direction: row;
    border-radius: 48px;
    justify-content: space-around;
    align-items: center;
    column-gap: 10px;
    padding: spacings.$XXS;
    margin: auto;
    max-width: 1000px;
    background-color: colors.$white;

    > div {
      flex-basis: 180%;
      margin: 0;
      padding: 0;
    }

    > div:nth-of-type(1) {
      &:after {
        content: '';
        display: block;
        width: 1px;
        height: spacings.$XXL;
        order: 2;
        background-color: colors.$neutral200;
        position: absolute;
        top: 0;
        right: -5px;
      }
    }

    input {
      border-color: transparent;
      height: 48px;
      &:focus {
        border-color: transparent;
      }
    }
  }
}

.lowerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  z-index: 1;
  margin: 24px auto 0;
  max-width: 400px;
  width: 100%;

  &:has(:is(.googleReviews, .trustpilotReviews)):has(.partOfRenault) {
    &::after {
      content: '';
      display: block;
      order: 1;
      width: 1px;
      height: 26px;
      flex: 0 0 1px;
      background-color: colors.$purple300;
    }
  }
}

.partOfRenault {
  order: 2;

  > div > div {
    margin-right: spacings.$XXS !important;
    text-wrap: nowrap;
  }
}

.trustpilotReviews {
  translate: 0 spacings.$XS;
}

.column {
  position: relative;
  margin-bottom: 0;
  @include mediaqueries.smallUp {
    margin-bottom: 0;
  }
  @include mediaqueries.largeUp {
    margin-bottom: 0;
  }
}

:export {
  smallBreakpoint: breakpoints.$smallBreakpoint;
  mediumBreakpoint: breakpoints.$mediumBreakpoint;
  largeBreakpoint: breakpoints.$largeBreakpoint;
}

.grandLabelContainer {
  text-align: center;
}

.grandLabel {
  transform: rotate(-3.984deg);
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;

  span {
    &:last-child {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }

  @include mediaqueries.mediumUp {
    position: absolute;
    right: 0;
    top: 12%;
  }
}