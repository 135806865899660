@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/variables/colors';
@use '$styles/variables/radii';

.component {
  padding-top: 0;
  color: colors.$neutral800;
  p,
  b {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    font-family: 'Jokker';
    color: colors.$neutral800;
    text-align: center;
    margin-block-start: 0;
  }
  b {
    color: colors.$purple500;
  }
}
.heading {
  margin-bottom: 32px;
  p {
    color: colors.$neutral800;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    margin: 0;
  }
}
.darkTracks {
  display: none; // hide for now
  position: absolute;
  left: 70px;
  height: 50%;
  width: 30px;
  background-image: url('https://res.cloudinary.com/fxtr/image/upload/v1688133089/landing/hero-landing/tracks-bg-light-down_d7qdf0.png');
  background-repeat: repeat-y;
  background-position: top 0;
  z-index: 0;
}

.row {
  padding-top: 40px;
}

.col {
  position: relative;
  z-index: 2;
}

.cards {
  display: flex;
  flex-direction: column;
  background-color: colors.$white;
  padding: 32px 16px;
  border-radius: 24px;
  margin: 16px 0;
}
.card {
  box-shadow: none;
  padding: 0 10px;
}
.card:not(:last-child) {
  border-bottom: 1px solid colors.$neutral200;
}
.card:last-child {
  p {
    margin-bottom: 0;
  }
}
.card:not(:first-child) {
  padding-top: 32px;
}

@include mediaqueries.upToMedium {
  .component {
    padding-bottom: 0;
  }

  // put car image at the bottom
  .col {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .cards {
    padding-bottom: 64px;
  }

  img.car {
    margin-top: -80px;
  }
}

@include mediaqueries.smallUp {
  .component {
    position: relative;
    text-align: left;
    padding: 80px 0;
    p,
    b {
      font-size: 24px;
      line-height: 28px;
    }
  }
  .container {
    margin: 0;
  }

  .heading {
    margin-bottom: 32px !important;
    text-align: center;
    p {
      color: colors.$purple800;
      font-size: 40px;
      line-height: 44px;
      text-align: center;
      margin: 0;
      display: inline;
    }
  }

  img {
    &.car {
      display: block;
      position: absolute;
      width: 280px;
      height: auto;
      left: -120px;
      bottom: -40px;
    }
  }

  .darkTracks {
    display: block;
    position: absolute;
    left: -99%;
    bottom: 45px;
    width: 100%;
    height: 53px;
    background-image: url('https://res.cloudinary.com/fxtr/image/upload/v1688135788/landing/hero-landing/tracks_bg_dark_sgic6r.png');
    background-repeat: repeat-x;
    background-position: bottom 0;
  }

  .row {
    position: static;
    padding-top: 0;
    background-image: url('https://res.cloudinary.com/fxtr/image/upload/v1688133089/landing/hero-landing/tracks_bg_light_o6xqym.png');
    background-repeat: repeat-x;
    background-position: right 0 bottom 130px;
  }

  .colDarkTracks {
    display: block;
    position: absolute;
    left: 0;
    bottom: 45px;
    width: 75px;
    height: 53px;
    background-image: url('https://res.cloudinary.com/fxtr/image/upload/v1688135788/landing/hero-landing/tracks_bg_dark_sgic6r.png');
    background-repeat: repeat-x;
    background-position: bottom 0;
  }

  .cards {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    background-color: transparent;
    padding: 0;

    p {
      margin-bottom: 0;
    }
  }
  .card {
    width: calc((100% - 64px) / 3);
    min-height: 132px;
    background-color: colors.$white;
    border-radius: 8px;
    padding: 16px;
    box-shadow:
      0px 0px 1px 1px rgba(107, 74, 255, 0.08),
      0px 3px 5px 0px rgba(107, 74, 255, 0.12);
    z-index: 2;
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    p {
      text-align: left;
    }
    &:not(:last-child) {
      border-bottom: none;
    }
    &:not(:last-child):not(:nth-child(3)) {
      margin-right: 32px;
    }
    &:not(:first-child) {
      padding-top: 12px;
    }
  }
}

@include mediaqueries.mediumUp {
  .row {
    background-position: right 0 bottom 70px;
  }

  img {
    &.car {
      bottom: -100px;
    }
  }
}

@include mediaqueries.largeUp {
  .component {
    p,
    b {
      font-size: 18px;
      line-height: 24px;
    }
  }
  .heading {
    text-align: left;
    margin-bottom: 72px !important;
    font-size: 56px !important;
    line-height: 56px;
    p {
      display: block;
      text-align: left;
      font-size: 56px !important;
      line-height: 56px;
    }
  }
  .container {
    margin: 0 0 0 182px;
  }

  .row {
    background-position: right 0 bottom 25px;
  }

  .cards {
    justify-content: space-between;
    flex-wrap: nowrap;
    margin: 0;
  }
  .card {
    padding: 12px;
    width: 20%;
    min-height: 140px;
    margin-bottom: 0;
    &:not(:last-child) {
      margin-right: 16px !important;
    }
  }

  img {
    &.car {
      left: -100px;
      bottom: -130px;
    }
  }
}

@include mediaqueries.extraLargeUp {
  .component {
    .card {
      padding: 20px;
      &:not(:first-child) {
        padding-top: 20px;
      }
      p,
      b {
        font-size: 24px;
        line-height: 30px;
      }
    }
    .darkTracks {
      bottom: 55px;
    }

    .colDarkTracks {
      bottom: 55px;
    }
  }
}
