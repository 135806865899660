@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/spacings';
@use '$styles/variables/colors';
@use '$styles/tools/mixins/typography';
@use '$styles/mediaqueries';

.component {
  background-color: colors.$purple100;
}
.linksCard {
  flex: 1 1 100%;
  padding: spacings.$M spacings.$M spacings.$XL;
  background-color: colors.$neutral100;
}

.heading {
  color: colors.$neutral800;

  @include mediaqueries.upToLarge {
    @include typography.headlinesH4TypographyStyles;
  }
}

.linksList {
  list-style: none;
  padding: 0;
  margin: 0;
  ::first-letter {
    text-transform: uppercase;
  }

  @include mediaqueries.smallUp {
    columns: 2;
  }

  > li {
    margin-bottom: spacings.$XS;
  }
}

.viewAllLink {
  padding: 0 !important;
  margin-top: spacings.$M;
}

.TopLocationsAndServicesWrapper {
  @include mediaqueries.extraSmallOnly {
    padding: 0;
  }
}

.TopLocationsAndServicesContainer {
  padding: 40px 16px;
  background-color: colors.$white;

  @include mediaqueries.extraSmallOnly {
    border-radius: 0;
  }
  @include mediaqueries.smallUp {
    padding: spacings.$XL (spacings.$XL + spacings.$S);
  }
}

$mobileGap: spacings.$L;
$tabletGap: spacings.$XL;

.LinksRow {
  display: flex;
  gap: $mobileGap;
  flex-wrap: wrap;
  margin-top: spacings.$XXL;

  @include mediaqueries.smallUp {
    flex-wrap: nowrap;
    gap: $tabletGap;
  }
}

.SubheadingHighlighted {
  margin-bottom: spacings.$XS;
  p,
  & {
    @include typography.paragraphMTypography;
    font-weight: 600;
    color: colors.$neutral600;
  }
  p {
    margin-bottom: 0;
  }
}

.Subheading {
  p,
  & {
    margin-bottom: 0;
    @include typography.paragraphMTypography;
    color: colors.$neutral600;
  }
}
:export {
  white: colors.$white;
}
