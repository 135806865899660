@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/tools/mixins/typography';
@use '$styles/variables/colors';
@use '$styles/variables/spacings';

.container {
  padding-top: 40px;
  padding-bottom: 40px;
  @include mediaqueries.mediumUp {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.spacer {
  @include mediaqueries.upToMedium {
    height: 30px;
  }
}

.mobileHeading {
  @include mediaqueries.mediumUp {
    display: none;
  }
}

.desktopHeading {
  @include mediaqueries.upToMedium {
    display: none;
  }
}
.Header {
  margin-bottom: 24px;
  text-align: center;

  @include mediaqueries.mediumUp {
    margin-bottom: 44px;
  }
}

.Heading {
  @include typography.headlinesH3TypographyStyles;
  font-size: 32px;
  color: colors.$neutral800;

  @include mediaqueries.smallUp {
    @include typography.headlinesH2TypographyStyles;
    font-size: 56px;
    line-height: 56px;
  }
}
.Subheading {
  font-size: 24px;
  @include mediaqueries.smallUp {
    @include typography.headlinesH3TypographyStyles;
    color: colors.$neutral600;
  }
}
.leftAlignedHeading {
  text-align: left;
}
.text {
  @include mediaqueries.upToMedium {
    margin-top: spacings.$L;
  }
}
.media {
  > div {
    width: 100%;
  }

  @include mediaqueries.mediumUp {
    width: 100%;
    display: flex;

    &.-column2 {
      justify-content: flex-end;
    }
  }

  .MediaBlock {
    margin: 0 auto;
    @include mediaqueries.mediumUp {
      width: 100%;
      margin: 0;
    }
    &.-video {
      max-width: 550px;
    }
  }
}
