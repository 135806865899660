@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/variables/spacings';
@use '$styles/variables/elevation';
@use '$styles/variables/breakpoints';
@use '$styles/mediaqueries';
@use '$styles/tools/extends/typography';
@use '$styles/tools/mixins/typography' as typographyMixins;
@use '$styles/variables/fonts';

.col__ctaLookupModal {
  button {
    @include mediaqueries.extraSmallOnly {
      width: 100%;
    }
  }
  @include mediaqueries.smallUp {
    height: 104px;
  }
  @include mediaqueries.largeUp {
    height: 132px;
  }
}

// FR layout is slightly different than UK
.heroCNDAvailable {
  &.topAdjustment {
    @include mediaqueries.smallUp {
      &:global(.top) {
        padding-top: 80px !important;
      }
    }
  }

  @include mediaqueries.smallUp {
    &:global(.top) {
      padding-bottom: spacings.$L;
    }
  }

  @include mediaqueries.mediumOnly {
    &:global(.top) {
      padding-bottom: 0;
    }
  }

  .grandLabel {
    transform: rotate(-3.984deg);
    margin: 16px 0; // fill in the space of rotation
  }

  .trustpilot {
    min-width: 200px; // avoid layout shift when iframe loads
    margin: 0;

    @include mediaqueries.upToMedium {
      transform: scale(0.8);

      > iframe {
        left: -20px;
      }
    }

    @include mediaqueries.mediumUp {
      min-width: 420px; // avoid layout shift when iframe loads
      height: 20px;
    }
  }

  .trustpilotFR {
    @include mediaqueries.mediumUp {
      min-width: 300px; // avoid layout shift when iframe loads
    }
  }

  .headings {
    @include mediaqueries.upToSmall {
      text-align: center;
    }

    .hero__title {
      margin-top: spacings.$L;

      @include mediaqueries.smallUp {
        margin-bottom: spacings.$S;
      }

      @include mediaqueries.largeUp {
        margin-top: 56px;
        margin-bottom: 20px;
      }
    }

    .hero__subheading {
      @include typographyMixins.labelLTypographyStyles;
      font-weight: 600;
      letter-spacing: unset;
      margin-bottom: spacings.$S;

      @include mediaqueries.mediumUp {
        font-size: 22px;
      }
    }

    .grandLabel {
      @include mediaqueries.smallUp {
        display: none;
      }
    }
  }

  .colVrmLookup {
    > .ctaLookupModal {
      margin: spacings.$L 0;

      @include mediaqueries.mediumUp {
        margin: spacings.$M 0;
        height: auto;
      }

      a {
        width: 100%;
      }
    }
  }

  .row {
    .colVrmLookup {
      > .col__ctaLookupModal.ctaLookupModal {
        margin: spacings.$M 0 !important;

        @include mediaqueries.upToSmall {
          margin: spacings.$L 0 !important;
        }

        @include mediaqueries.largeUp {
          margin-top: 36px !important;
          margin-bottom: spacings.$L !important;
        }
      }
    }
  }

  .hero {
    &__title {
      @include mediaqueries.largeUp {
        width: 110%;
      }
    }

    &__subheading {
      color: colors.$white;
    }

    &__car {
      &_picture {
        transform: scale(0.9);
        @include mediaqueries.largeUp {
          transform: scale(0.8);
        }
      }
      &_asset {
        transform: rotate(-13.181deg);
        @include mediaqueries.smallUp {
          transform: translate(0, 0);
        }
        @include mediaqueries.mediumOnly {
          transform: translate(-18px, 52px);
        }
        @include mediaqueries.largeUp {
          transform: translate(-130px, 73px);
        }
        @include mediaqueries.extraLargeUp {
          transform: translate(-130px, 20px);
        }
      }
    }

    &__review {
      display: flex;
      justify-content: space-around;
      height: 75px;

      @include mediaqueries.mediumUp {
        justify-content: flex-start;
        align-items: center;
        margin-bottom: spacings.$L;
      }

      .renault {
        position: relative;
        &:before {
          content: '';
          display: block;
          width: 1px;
          height: 46px;
          position: absolute;
          left: -8px;
          background-color: transparentize($color: colors.$purple300, $amount: 0.1);

          @include mediaqueries.mediumUp {
            left: 5px;
            margin-top: 10px;
          }
        }
      }
    }

    &__renault {
      position: relative;
      .grandLabel {
        display: none;
        position: absolute;
        top: -110px;
        right: -110px;

        @include mediaqueries.smallUp {
          display: inline-flex;
          right: -10px;
          left: -80px;
        }
        @include mediaqueries.mediumUp {
          top: -80px;
          right: -60px;
          left: unset;
        }
        @include mediaqueries.largeUp {
          top: -130px;
        }
      }
    }
  }
}

.trustpilot {
  @include mediaqueries.smallUp {
    margin-bottom: 20px;
  }
  @include mediaqueries.mediumUp {
    margin-bottom: 26px;
  }
  @include mediaqueries.largeUp {
    margin-bottom: 28px;
  }
}

.colVrmLookup {
  z-index: 1;
}

.col {
  position: relative;

  @include mediaqueries.upToSmall {
    &__images {
      order: unset;
    }
  }

  @include mediaqueries.smallUp {
    &__images {
      grid-row: 1 / 3;
    }
  }

  @include mediaqueries.largeUp {
    &__images {
      grid-row: 1 / 4;
    }
  }
  &__ctaLookupModal {
    @include mediaqueries.upToSmall {
      margin-bottom: 48px;
      margin-top: 20px;
    }
    @include mediaqueries.smallUp {
      min-width: min-content;
      width: 100%;
      max-width: 307px;
    }
  }
}

.row {
  background: linear-gradient(90deg, #5739D8 0%, #3A1DA7 100%);
  overflow: hidden;

  @include mediaqueries.smallUp {
    min-height: 520px;
    &:global(.top) {
      padding-top: 80px;
    }
  }
  @include mediaqueries.mediumUp {
    min-height: 520px;
  }
  @include mediaqueries.largeUp {
    min-height: 600px;
  }
  @include mediaqueries.upToSmall {
    .colVrmLookup {
      .col__ctaLookupModal {
        margin-bottom: spacings.$M !important;
      }
    }
    .col {
      &__images {
        order: 4;
      }
      .hero {
        &__title {
          margin-bottom: spacings.$S;
          line-height: 44px;
        }
        &__subheading {
          margin-bottom: spacings.$S;
        }
        &__lines {
          display: block;
        }
      }
    }
  }

  .col {
    .hero {
      &__title {
        font-family: fonts.$campaignFontFamily;
        font-size: 40px;
        letter-spacing: 0.4px;
        @include mediaqueries.mediumUp {
          font-size: 46px;
          letter-spacing: 0.46px;
          line-height: 48px;
          font-weight: 700;
        }
        @include mediaqueries.largeUp {
          font-size: 80px;
          line-height: 80px;
          letter-spacing: 0.8px;
        }
      }
      &__renault {
        @include mediaqueries.smallUp {
          justify-content: flex-end;
        }
      }
    }
  }
}

.hero {
  &__lines {
    position: absolute;
    @include mediaqueries.upToSmall {
      width: 585px;
      height: auto;
      top: -122px;
      left: 50%;
      transform: translate(-118px);
    }

    @include mediaqueries.smallOnly {
      width: 585px;
      height: 585px;
      right: -327px;
      bottom: -145px;
    }

    @include mediaqueries.mediumOnly {
      width: 500px;
      height: 500px;
      left: -18px;
      top: 0px;
    }

    @include mediaqueries.largeUp {
      width: 855px;
      left: -240px;
      bottom: -55%;
    }
  }
}

.hero {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;
  &__lines {
    @include mediaqueries.upToSmall {
      display: none; // hide the lines for mobile for no
    }
  }
  &__title {
    margin: 0 auto 24px;
    color: colors.$white !important;
    white-space: pre-wrap;
    &::first-letter {
      text-transform: uppercase;
    }

    @include mediaqueries.smallUp {
      @include typographyMixins.headlinesH2TypographyStyles;
      margin-bottom: 32px;
    }

    @include mediaqueries.upToLarge {
      margin-top: 20px;
    }

    @include mediaqueries.largeUp {
      @include typographyMixins.displayMTypographyStyles;
      margin-top: 45px;
      margin-bottom: 40px;
    }
  }

  &__subheading {
    @include typographyMixins.labelMTypographyStyles;
    font-weight: 600;
    color: colors.$purple300;

    @include mediaqueries.smallUp {
      @include typographyMixins.headlinesH6TypographyStyles;
      margin-bottom: 40px;
    }
  }

  &__vrmlookup,
  &__form__vrmlookup {
    position: relative;
    z-index: elevation.$level1 !important;
    margin-bottom: 36px;
    @include mediaqueries.extraSmallOnly {
      margin-top: 47px;
    }
    @include mediaqueries.smallUp {
      margin-bottom: 48px;
    }
  }

  &__car {
    &_picture {
      display: block;
      position: relative;
      transform: scale(0.9);
    }
    &_asset {
      @include mediaqueries.smallOnly {
        transform: translate(40px, 0);
        position: absolute;
        right: 0;
        top: 18px;
      }
      @include mediaqueries.upToSmall {
        transform: translate(0, 30px);
        position: relative;
        display: block;
        margin: auto;
      }
      @include mediaqueries.mediumOnly {
        transform: translate(42px, 52px);
      }
      @include mediaqueries.largeUp {
        transform: translate(-89px, 73px);
      }
    }
  }

  @include mediaqueries.upToMedium {
    display: inline-flex;
    align-self: flex-end;
  }

  @include mediaqueries.smallUp {
    display: flex;
    flex-direction: row;
    padding: 0;
    min-height: 720px;

    &__form {
      order: 1;
      flex-direction: column;

      &__reviewdesktop {
        display: block;
        position: absolute;
        width: 100%;
        max-width: 420px;
        bottom: 23px;
        left: 42px;
        order: 3;
        padding: 0;
        margin: 0;
      }
    }

    &__lines {
      display: block;

      @keyframes fadeInLines {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }

      &.AnimateLinesAppearance {
        opacity: 0;
        animation: 1s 5s 1 normal forwards fadeInLines;
      }
    }
  }

  @include mediaqueries.largeUp {
    &__title {
      display: block;
      margin-bottom: 40px;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
}

:export {
  smallBreakpoint: breakpoints.$smallBreakpoint;
  mediumBreakpoint: breakpoints.$mediumBreakpoint;
  largeBreakpoint: breakpoints.$largeBreakpoint;
}
