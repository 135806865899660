@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/tools/mixins/typography';
@use '$styles/variables/colors';
@use '$styles/mediaqueries';

.listItem {
  display: flex;
  align-items: flex-start;
  p {
    margin-bottom: 0;
    @include typography.paragraphMTypography;
    color: colors.$neutral600;

    @include mediaqueries.smallUp {
      color: colors.$neutral700;
    }
  }
  :global(.tick) {
    margin-right: 14px;
  }
  :global(.bullet) {
    margin-right: 7px;
  }
}
