@use "src/styles/theme" as *; $themeName: "fixtercore";

.Avatar {
  &.small {
    width: 50px !important;
    height: 50px !important;
  }
  &.large {
    width: 100px !important;
    height: 100px !important;
  }
  clip-path: inset(0% 0% 0% 0% round 12px);
}
