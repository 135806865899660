@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/variables/fonts';
@use '$styles/variables/spacings';
@use '$styles/mediaqueries';

.component {
  background-color: var(--background-color);
  padding: 34px 0 26px;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: center;
  font: fonts.$headlinesH5;
  letter-spacing: -0.48px;
  margin: auto auto 0;
  color: var(--text-color, colors.$purple800);
  @include mediaqueries.upToMedium {
    margin-bottom: spacings.$M;
  }
}

.logos {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: spacings.$M;

  @include mediaqueries.upToMedium {
    flex-wrap: wrap;
    gap: spacings.$L;
  }
}

.logosFR {
  gap: spacings.$L;

  @include mediaqueries.upToMedium {
    flex-wrap: wrap;
  }

  @include mediaqueries.mediumUp {
    gap: spacings.$XL;
    justify-content: center;
  }
}

.logoItem {
  max-width: 180px;
  @include mediaqueries.upToMedium {
  }
}
