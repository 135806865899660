@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/tools/mixins/typography';
@use '$styles/mediaqueries';
@use '$styles/variables/spacings';
@use '$styles/variables/colors';
@use '$styles/values';

.page {
  background-color: white;

  &:has(:global(.top)) {
    padding-top: 80px !important;
    margin-top: -80px;
  }
}

.section {
  padding-top: spacings.$M;

  @include mediaqueries.largeUp {
    padding-top: spacings.$XL;
  }

  :global(.Row) {
    row-gap: spacings.$XXL;
  }
}

.article {
  display: flex;
  flex-direction: column;
  gap: spacings.$XL;

  > :global(.RichTextArticle) {
    padding-top: 0;
  }

  h2 {
    @include typography.headlinesH5TypographyStyles;
    margin-bottom: 1em; // 24px

    @include mediaqueries.largeUp {
      @include typography.headlinesH4TypographyStyles;
    }
  }

  h3 {
    @include typography.labelLTypographyStyles;
    font-weight: 600;
    margin-bottom: 1.333334em; // 24px

    @include mediaqueries.largeUp {
      @include typography.headlinesH6TypographyStyles;
    }
  }

  h4,
  h5 {
    @include typography.labelMTypographyStyles;
    margin-bottom: 0.75em; // 12px

    @include mediaqueries.largeUp {
      @include typography.labelLTypographyStyles;
      font-weight: 600;
    }
  }

  p,
  ul,
  ol {
    @include typography.paragraphMTypography;

    &:last-child {
      margin-bottom: 2.5em; // 40px
    }

    // main heading inside the article must have top spacing
    &+h2 {
      margin-top: 1.666667em; // 40px
    }
  }

  ul,
  ol {
    padding-left: 1.5em;

    >li>p {
      margin: 1em 0 1em 0.3em;

      &:last-child {
        margin: 1em 0 1em 0.3em;
      }
    }
  }
}

.aside {
  display: flex;
  flex-direction: column;
  gap: spacings.$XXL;

  @include mediaqueries.upToMedium {
    > :global(.CmsVrmLookupTrust) {
      display: none;
    }
  }
}

.main {
  background-color: colors.$purple100;
}

.bottom {
  background-color: colors.$white;
}

// reorder promo banner and nav for AB Test - GROW-1084
:global(.sticky-nav) {
  .flipPromoBanner {
    @include mediaqueries.smallUp {
      position: fixed;
      top: -56px;
      transform: translateY(var(--nav-scroll, 56px));
      z-index: values.$elevationTop;
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    @include mediaqueries.mediumUp {
      top: -60px;
      transform: translateY(var(--nav-scroll, 60px));
    }

    @include mediaqueries.smallUp {
      +.page {
        &:has(:global(.top)) {
          // padding-top: 112px !important;
          margin-top: 0;

          padding-top: 56px !important;
        }
      }
    }
  }

  @include mediaqueries.smallUp {
    .promoBanner {
      order: 1;
      position: relative;
      z-index: values.$elevationTop + 2;
    }

    .navbar {
      order: 2;
    }
  }
}