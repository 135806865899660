@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/variables/fonts';
@use '$styles/variables/elevation';
@use '$styles/mediaqueries';

@mixin computeTriangleShapeSize($size) {
  $shapeSize: $size;
  $rightPosition: -($shapeSize / 2 + 0.5);

  width: $shapeSize;
  height: $shapeSize;

  top: 50%;
  translate: 0 -50%;
  right: $rightPosition;
}

.SpeechBubble {
  &,
  p {
    font-family: fonts.$headlinesFontFamily;
    font-weight: 600;
    font-size: 22px;
    line-height: 1em !important;
    letter-spacing: calc(1em / 100) !important;
    color: colors.$white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 0;
    margin-right: 20px;
  }
  p {
    margin: 0;
  }
  position: relative;
  width: 100%;
  border: 1px solid colors.$alphaPurple30;

  &::after {
    content: '';
    position: absolute;
    z-index: elevation.$level1;

    $shapeSize: 17px;
    
    width: $shapeSize;
    height: $shapeSize;

    top: 50%;
    translate: 0 -50%;
    right: -($shapeSize / 2 + 0.5);

    border: 1px solid colors.$alphaPurple30;

    border-top-right-radius: 3px;

    transform: rotate(45deg);

    
  }
  &.rightTail {
    &::after {
      border-bottom: initial;
      border-left: initial;
    }
  }

  &.leftTail {
    &::after {
      right: auto;
      left: -(17px / 2 + 0.5);
      border-top: initial;
      border-right: initial;
    }
  }

  &.small {
    font-size: 18px;
    min-width: 181px;
    min-height: 64px;
    border-radius: 12px;

    // @TODO - gradient color doesn't exist in color scheme
    background: linear-gradient(340.32deg, #311e82 38.18%, #5137c2 154.59%);

    @include mediaqueries.largeUp {
      border-radius: 14px;
    }

    &.rightTail::after {
      // @TODO - color doesn't exist in color scheme
      background: #311e82;
    }
    &.leftTail::after {
      // @TODO - color doesn't exist in color scheme
      background: linear-gradient(108deg, #3B2996, #3A2894, #382790);
    }
  }

  &.medium {
    font-size: 20px;
    min-width: 205px;
    min-height: 72px;
    border-radius: 14px;

    // @TODO - gradient color doesn't exist in color scheme
    background: linear-gradient(350.36deg, #1a055b 15.01%, #3b1d9d 98.9%, #512cc9 113.08%);

    @include mediaqueries.largeUp {
      border-radius: 17px;
      min-height: 91px;
      min-width: 237px;
    }

    &.rightTail::after {
      // @TODO - color doesn't exist in color scheme
      background: linear-gradient(350.36deg - 45deg, #1a055b 2%, #3b1d9d 200%, #512cc9 130%);
    }
    &.leftTail::after {
      // @TODO - color doesn't exist in color scheme
      background: linear-gradient(108deg, #2F1887, #2D1783, #2A147E);
    }
  }

  &.large {
    font-size: 20px;
    // width: 100%;
    min-width: 229px;
    min-height: 80px;
    border-radius: 15px;

    @include mediaqueries.largeUp {
      border-radius: 18px;
      font-size: 24px;
      min-width: 276px;
      min-height: 96px;
    }

    // @TODO - gradient color doesn't exist in color scheme
    background: linear-gradient(343.89deg, #110145 16.59%, #240a78 96.41%, #4521b8 107.42%);

    &.rightTail::after {
      // @TODO - color doesn't exist in color scheme
      background: linear-gradient(343.89deg - 45deg, #110145 60%, #240a78 155%, #4521b8 107.42%);
    }
    &.leftTail::after {
      background: linear-gradient(108deg, #1D096A, #1C0967, #1B0864);
    }
  }
  &.promo {
    min-height: 91px;
    border-radius: 14px;
    min-width: 250px;
    @include mediaqueries.largeUp {
      min-width: 276px;
    }

    // @TODO - gradient color doesn't exist in color scheme
    background: linear-gradient(343deg, #128643 7.6%, #32b251 145.2%);
    border: 1px solid rgba(124, 218, 164, 0.7);

    p {
      padding: 0;
      font-size: 20px;
      @include mediaqueries.largeUp {
        font-size: 23px;
      }
    }

    &::after {
      // @TODO - color doesn't exist in color scheme
      background: linear-gradient(120deg, #198c47 10%, #158a44 140.98%);
      border-right: 1px solid rgba(124, 218, 164, 0.7);
      border-top: 1px solid rgba(124, 218, 164, 0.7);
      // @include computeTriangleShapeSize(17px);
    }
  }
}

.shadow {
  box-shadow: 0px 2px 4px rgba(45, 28, 121, 0.48), 5.22595px 5.22595px 24px rgba(51, 37, 110, 0.32);
}
