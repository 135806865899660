@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/values';
@use '$styles/variables/breakpoints';
@use '$styles/tools/mixins/typography';

.component {
  position: relative;
  z-index: 1;
}

.contents {
  padding-top: values.$space20;
  padding-bottom: values.$space11;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    height: 100%;
    clip-path: ellipse(160% 100% at 50% 0%);
    mix-blend-mode: multiply;
  }

  @include mediaqueries.upToSmall {
    &::after {
      background: linear-gradient(180deg, rgb(0 0 0 / 0), rgb(12 12 12 / 74.5%));
    }
  }

  @include mediaqueries.smallUp {
    margin-top: values.$space20 * -1;
    padding-top: values.$space20 + values.$space16;
    padding-bottom: calc(values.$space6 + values.$space20);

    &::after {
      clip-path: ellipse(105% 98% at 50% 0%);
      background: linear-gradient(-90deg, rgba(0, 0, 0, 0) 0%, #000 74.5%);
      opacity: 0.8;
    }
  }
}

:global(.flipPromoBanner) {
  + main {
    .contents {
      margin-top: 0;

      @include mediaqueries.smallUp {
        padding-top: values.$space20 + values.$space16;
      }
    }
  }
}

.heading {
  font-family: values.$fontFamilyCampaign;
  font-size: 40px;
  text-shadow: values.$textShadow3;
  letter-spacing: 0.4px;
  line-height: 44px;
  margin-bottom: values.$space4;
  color: values.$colorWhite;
  white-space: pre-wrap;

  @include mediaqueries.upToSmall {
    text-align: center;
  }

  @include mediaqueries.mediumUp {
    font-size: 46px;
    letter-spacing: 0.46px;
    line-height: 48px;
    font-weight: 700;
  }

  @include mediaqueries.largeUp {
    font-size: 80px;
    line-height: 80px;
    letter-spacing: 0.8px;
  }
}

.tagline {
  @include typography.labelLTypographyStyles;
  font-weight: 600;
  margin: 0 auto;
  color: values.$colorWhite;
  white-space: pre-wrap;

  @include mediaqueries.upToSmall {
    text-align: center;
    margin-bottom: values.$space4;
  }

  @include mediaqueries.mediumUp {
    font-size: 22px;
  }
}

.grandLabelContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.grandLabel {
  transform: rotate(-3.984deg);
  margin-top: values.$px_10;
  margin-bottom: values.$px_10;
  background-image: linear-gradient(95deg, values.$colorPurple500 33.34%, values.$colorPurple600 104.95%);
}

.ctaContainer {
  @include mediaqueries.upToSmall {
    text-align: center;
  }
}

.cta {
  min-width: min-content;
  width: 100%;
  max-width: 307px;
  margin-top: values.$space8;
  margin-bottom: values.$space8;

  @include mediaqueries.upToSmall {
    width: 100%;
    margin-top: values.$space6;
    margin-bottom: values.$space10;
    margin-right: 0 !important;
  }
}

.trustpilotContainer {
  display: flex;
  justify-content: space-around;
  height: 75px;

  @include mediaqueries.mediumUp {
    justify-content: flex-start;
    align-items: center;
    margin-bottom: values.$space6;
  }
}

.trustpilot {
  min-width: 200px; // avoid layout shift when iframe loads
  margin: 0;

  @include mediaqueries.upToMedium {
    transform: scale(0.8);

    > iframe {
      left: -20px;
    }
  }

  @include mediaqueries.mediumUp {
    min-width: 420px; // avoid layout shift when iframe loads
    height: 20px;
  }
}

.trustpilotFR {
  @include mediaqueries.mediumUp {
    min-width: 300px; // avoid layout shift when iframe loads
  }
}

.renault {
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 1px;
    height: 46px;
    position: absolute;
    left: -8px;
    background-color: transparentize($color: values.$colorPurple300, $amount: 0.1);

    @include mediaqueries.mediumUp {
      left: 5px;
      margin-top: 10px;
    }
  }
}

@include mediaqueries.smallUp {
  .taglineContainer {
    grid-row-start: 2;
  }

  .grandLabelContainer {
    grid-row-start: 3;
  }

  .ctaContainer {
    grid-row-start: 3;
  }

  .trustpilotContainer {
    grid-row-start: 4;
  }
}

.image {
  object-fit: cover;
  object-position: top;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  clip-path: ellipse(160% 100% at 50% 0%);

  @include mediaqueries.smallUp {
    height: 100%;
    clip-path: ellipse(105% 98% at 50% 0%);
  }

  > img {
    object-fit: cover;
    object-position: top;
    width: 100%;
    height: 100%;
  }
}

:export {
  smallBreakpoint: breakpoints.$smallBreakpoint;
  mediumBreakpoint: breakpoints.$mediumBreakpoint;
}
